.App {
  text-align: center;
}

.loginDesktop{
  display: block;
  position: relative;
  margin: 10px;
}
#container {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 60%;
  }
  #yours {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 18px;
    right: 26%;
  }
  #theirs {
    width: 55%;
    height: 500px;
    margin: 0px 20%;
  }

  .online-1 {
    width: 55%;
    margin: 0px 20%;
  }

.loginMobile{display: none;}

@media screen and (max-width: 768px) {

.loginDesktop{display: none;}
.loginMobile { 
  display: block;
}

#container {
  width: 100%;
  height: 500px;
}
h5 {
  font-size: smaller;
} 

#theirs {
  width: 90%;
  height: 500px;
  margin: 0px 5%;
}
#yours {
  right: 10%;
}
 
}

.login {
  width: 50%;
  margin: 0 25%;
}

  video {
  background: black;
  border: 1px solid gray;
  }
 
  

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
